// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-404-notfound-tsx": () => import("./../../../src/pages/404/notfound.tsx" /* webpackChunkName: "component---src-pages-404-notfound-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-i-index-tsx": () => import("./../../../src/pages/i/index.tsx" /* webpackChunkName: "component---src-pages-i-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-make-index-tsx": () => import("./../../../src/pages/make/index.tsx" /* webpackChunkName: "component---src-pages-make-index-tsx" */),
  "component---src-pages-me-index-tsx": () => import("./../../../src/pages/me/index.tsx" /* webpackChunkName: "component---src-pages-me-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-support-mobile-help-index-tsx": () => import("./../../../src/pages/support/mobile/help/index.tsx" /* webpackChunkName: "component---src-pages-support-mobile-help-index-tsx" */),
  "component---src-templates-demos-schema-12-tsx": () => import("./../../../src/templates/demos/schema12.tsx" /* webpackChunkName: "component---src-templates-demos-schema-12-tsx" */),
  "component---src-templates-published-npc-gen-1-tsx": () => import("./../../../src/templates/published/npc-gen-1.tsx" /* webpackChunkName: "component---src-templates-published-npc-gen-1-tsx" */),
  "component---src-templates-published-schema-12-tsx": () => import("./../../../src/templates/published/schema12.tsx" /* webpackChunkName: "component---src-templates-published-schema-12-tsx" */),
  "component---src-templates-published-schema-14-tsx": () => import("./../../../src/templates/published/schema14.tsx" /* webpackChunkName: "component---src-templates-published-schema-14-tsx" */),
  "component---src-templates-published-schema-15-tsx": () => import("./../../../src/templates/published/schema15.tsx" /* webpackChunkName: "component---src-templates-published-schema-15-tsx" */)
}

